<template>
  <v-container fluid>
    <ExciseExceptionsFlat></ExciseExceptionsFlat
  ></v-container>
</template>
<script>
import ExciseExceptionsFlat from '@components/reports/exciseExceptionsFlat'
export default {
  //model: {
  //	prop: ''
  //},
  page: {
    title: 'Excise Exceptions Adhoc Report',
  },
  props: {},
  components: {
    ExciseExceptionsFlat,
  },
  filters: {},
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {},
  watch: {},
}
</script>
<style scoped>
</style>